import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["material", "toxin"]

  connect() {
    this.rules = JSON.parse(this.element.dataset.selectRules)
    this.refreshOptions()

    // watch for changes on the material select
    this.materialTarget.addEventListener("change", () => {
      this.refreshOptions()
    })
  }

  refreshOptions() {
    // If material select with label "Wheat" is selected, disable toxin select
    if (this.materialTarget.options[this.materialTarget.selectedIndex].text === "Wheat") {
        this.toxinTarget.disabled = true;
        document.querySelector("#riskWarning").classList.add("hidden")
    } else {
        this.toxinTarget.disabled = false;
      document.querySelector("#riskWarning").classList.remove("hidden")
    }

    this.toxinTarget.querySelectorAll("option").forEach((option) => {
      option.disabled = !this.rules[parseInt(this.materialTarget.value)].includes(parseInt(option.value))
    })

    if (this.toxinTarget.value === "" || this.toxinTarget.querySelector(`option[value="${this.toxinTarget.value}"]`).disabled) {
      this.toxinTarget.value = this.toxinTarget.querySelector("option:not([disabled])").value
    }
  }
}
