// app/javascript/controllers/risk_assessment_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  refreshForm(event) {
    let form = this.element.form

    let button = document.createElement('button')
    button.formAction = this.element.dataset.formaction
    button.formMethod = 'POST'
    button.formNoValidate = 'formnovalidate'
    button.type = 'submit'

    form.appendChild(button) // button need to be in the form to work

    form.requestSubmit(button) // use request submit to trigger event and so use turbo

    button.remove()
  }
}
