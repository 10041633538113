import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateAnimals(event) {
    let specie_id = event.target.value
    let animals_map = JSON.parse(this.element.dataset.animals)
    let new_animal_collection = animals_map[specie_id]

    const select_input = document.getElementById('what_if_animal_id');
    select_input.options.length = 1
    new_animal_collection.forEach(
      (element) => select_input.appendChild(new Option(element[0], element[1]))
    )
  }
}
